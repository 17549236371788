<template>
  <span class="treebox" style="width:100%;display:block;padding:0">
    <el-input
      :title="ruleForm.Trainingtype"
      v-model="ruleForm.Trainingtype"
      type="text"
      :size="size"
      placeholder="请选择"
      @focus="trainTypeClick"
      style="width:100%"
      :disabled="edaffirm == 1"
      clearable
      @clear="$emit('clearParams')"
      :readonly="readonly"
    />
    <el-dialog
      title="培训类型"
      :visible="dialogVisible"
      :before-close="doClose"
      :modal="modal"
      width="1100px"
    >
      <p style="color:red;text-align:left;display: flex;
    flex-direction: column;" v-show="showTrainTip">
        <span>* 河北地区技能培训请务必选择“职业/工种”和“培训等级”选项</span>
        <span>* 甘肃地区技能培训请务必选择“职业/工种”选项</span>
      </p>
      <p style="color:red;text-align:left" v-show="typeStu == false">
        <span>*</span>培训类型修改，请将各项内容重新选择
      </p>
      <p style="color:red;text-align:left" v-show="showGsyfTip">
        <span>*</span>工伤预防请选择“行业类型”和“职业/工种”选项
      </p>
      <div class="df">
        <div class="flexdcc">
          <p
            style="width:100%;border-bottom:0;min-height:40px"
            class="flexcc"
            v-if="typeStu == false"
          >
            {{
              this.name1 +
                (this.name2 != undefined ? this.name2 : "") +
                (this.name3 != undefined ? this.name3 : "") +
                (this.name4 != undefined ? this.name4 : "") +
                (this.name5 != undefined ? this.name5 : "")
            }}
          </p>
          <p
            v-else
            style="width:100%;border-bottom:0;min-height:40px"
            class="flexcc"
          >
            {{
              this.name1 +
                (this.name2 ? this.name2 : "") +
                (this.name3 ? this.name3 : "") +
                (this.name4 ? this.name4 : "") +
                (this.name5 ? this.name5 : "")
            }}
          </p>
          <div class="flexcc tree" style="width:100%">
            <div class="item flexdc">
              <h3>培训类型</h3>
              <div class="flex1 item-ovy-a">
                <el-tree
                  v-if="typeStu"
                  :expand-on-click-node="true"
                  highlight-current
                  class="filter-tree"
                  :data="trainTypeList"
                  :props="defaultProps"
                  :default-expand-all="false"
                  ref="tree"
                  @node-click="changeTrain"
                ></el-tree>
                <p v-else style="text-align:center">培训类型不支持修改</p>
              </div>
            </div>
            <div
              v-if="showPost"
              class="item flexdc"
              :class="shows == false ? '' : 'borderL0'"
            >
              <h3>岗位类型</h3>
              <div class="flexdc flex1">
                <el-input
                  size="mini"
                  placeholder="输入关键字进行过滤"
                  suffix-icon="el-icon-search"
                  v-model="postText"
                  class="inputree"
                ></el-input>
                <div class="flex1 item-ovy-a">
                  <el-tree
                    highlight-current
                    :expand-on-click-node="true"
                    class="filter-tree"
                    :data="PostTypeList"
                    :props="defaultProps"
                    :default-expand-all="false"
                    :filter-node-method="filterPost"
                    ref="postText"
                    @node-click="changePost"
                  ></el-tree>
                </div>
              </div>
            </div>
            <div
              v-if="showIndustry"
              class="item flexdc "
              :class="shows == false ? '' : 'borderL0'"
            >
              <h3>行业类型</h3>
              <div class="flexdc flex1">
                <el-input
                  size="mini"
                  placeholder="输入关键字进行过滤"
                  suffix-icon="el-icon-search"
                  v-model="IndustryText"
                  class="inputree"
                ></el-input>
                <div class="flex1 item-ovy-a">
                  <el-tree
                    highlight-current
                    :expand-on-click-node="true"
                    class="filter-tree"
                    :data="IndustryTypeList"
                    :props="defaultProps"
                    :default-expand-all="false"
                    :filter-node-method="filterIndustry"
                    ref="IndustryText"
                    @node-click="changeIndustry"
                  ></el-tree>
                </div>
              </div>
            </div>
            <div
              v-if="showOccupation"
              class="item flexdc "
              :class="shows == false ? '' : 'borderL0'"
            >
              <h3>职业/工种</h3>
              <div class="flexdc flex1">
                <el-input
                  size="mini"
                  placeholder="输入关键字进行过滤"
                  suffix-icon="el-icon-search"
                  v-model="OccupationText"
                  class="inputree"
                ></el-input>
                <div class="flex1 item-ovy-a">
                  <el-tree
                    highlight-current
                    :expand-on-click-node="true"
                    render-after-expand
                    class="filter-tree"
                    :data="OccupationTypeList"
                    :props="defaultProps"
                    :default-expand-all="false"
                    :filter-node-method="filterOccupation"
                    ref="OccupationText"
                    @node-click="changeOccupation"
                  ></el-tree>
                </div>
              </div>
            </div>
            <div
              v-if="showTrainLevel"
              class="item flexdc "
              :class="shows == false ? '' : 'borderL0'"
            >
              <h3>培训等级</h3>
              <div class="flexdc flex1">
                <el-input
                  size="mini"
                  placeholder="输入关键字进行过滤"
                  suffix-icon="el-icon-search"
                  v-model="LevelText"
                  class="inputree"
                ></el-input>
                <div class="flex1 item-ovy-a">
                  <el-tree
                    :expand-on-click-node="true"
                    highlight-current
                    class="filter-tree"
                    :data="TrainLevelTypeList"
                    :props="defaultProps"
                    :default-expand-all="false"
                    :filter-node-method="filterLevel"
                    ref="LevelText"
                    @node-click="changeLevel"
                  ></el-tree>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button class="bgc-bv" @click="doCancel">取 消</el-button>
        <el-button class="bgc-bv" @click="parentPsBack">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible="dialogVisibleSureTree"
      :before-close="treeClose"
      top="15%"
      width="600px"
    >
      <p>您确定要修改：</p>
      <p v-if="stus || postName">
        岗位类型：由【{{ postName }}】修改为：【<em
          style="color: red;display:initial"
          >{{ name21 }}</em
        >】
      </p>
      <p v-if="stus2 || industryNamePath">
        行业类型：由【{{ industryNamePath }}】修改为：【<em
          style="color: red;display:initial"
          >{{ name31 }}</em
        >】
      </p>
      <p v-if="stus3 && this.trainTypeId == '5'">
        职业工种：由【{{ occupationNamePath }}】修改为：【<em
          style="color: red;display:initial"
          >{{ name41 }}</em
        >】
      </p>
      <p v-if="stus4 || trainLevelName || name51">
        培训等级：由【{{ trainLevelName }}】修改为：【<em
          style="color: red;display:initial"
          >{{ name51 }}</em
        >】
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="treeClose" size="small">取 消</el-button>
        <el-button class="bgc-bv" @click="dialogSure" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </span>
</template>
<script>
export default {
  name: "treePopup",
  components: {},
  prop: ["ids"],
  data() {
    return {
      postText: "",
      IndustryText: "",
      OccupationText: "",
      LevelText: "",
      dialogVisible: false,
      activeName: "first",
      trainTypeList: [],
      PostTypeList: [], // 岗位
      showPost: false,
      IndustryTypeList: [], //行业
      showIndustry: false,
      OccupationTypeList: [], // 职业
      showOccupation: false,
      TrainLevelTypeList: [], //培训等级
      showTrainLevel: false,
      trainTypeId: "",
      postId: "",
      industryId: "",
      currentState: {
        showPost: false,
        showIndustry: false,
        showOccupation: false,
        showTrainLevel: false,
      },
    //   培训类型
      trainForm: {
        id: "",
        name: "",
        idPath: "",
      },
    //   岗位类型
      postForm: {
        id: "",
        name: "",
      },
    //   行业类型
      industryForm: {
        id: "",
        name: "",
      },
    //   职业类型
      occForm: {
        id: "",
        name: "",
      },
    //   培训等级
      levelForm: {
        id: "",
        name: "",
      },
      value: "",
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      name5: "",
      name21: "",
      name31: "",
      name41: "",
      name51: "",
      readonly: false,
      data: {},
      showTrainTip: false, // 显示技能培训提示
      shows: true,
      postName: "",
      industryNamePath: "",
      occupationNamePath: "",
      trainLevelName: "",
      dialogVisibleSureTree: false,
      stus: "",
      stus2: "",
      stus3: "",
      stus4: "",
      showGsyfTip:false, //工伤预防提示
    };
  },
  props: {
    ruleForm: {
      type: Object,
      default: function() {
        return "";
      },
    },
    size: {
      type: String,
      default: function() {
        return "medium";
      },
    },
    modal: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    type: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    typeStu: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    parentCanecl:{
      type:Function,
      default:null
    },
    // 是否需要校验有些字段是否必填(因为不知道具体要必填哪些字段)  1：是  其他：否
    threePostsLimit:{
        type:String,
        default:"0"
    }
  },
  created() {
    
  },
  watch: {
    postText: function(val) {
      this.$refs.postText.filter(val);
    },
    IndustryText: function(val) {
      this.$refs.IndustryText.filter(val);
    },
    OccupationText: function(val) {
      this.$refs.OccupationText.filter(val);
    },
    LevelText: function(val) {
      this.$refs.LevelText.filter(val);
    },
    showPost: function(val) {
      if (val == false) {
        this.name2 = "";
      }
    },
    showIndustry: function(val) {
      if (val == false) {
        this.name3 = "";
      }
    },
    showOccupation: function(val) {
      if (val == false) {
        this.name4 = "";
      }
    },
    showTrainLevel: function(val) {
      if (val == false) {
        this.name5 = "";
      }
    },
  },
  methods: {
    trainTypeClick() {
      this.showPop();

      if (this.typeStu == false) {
        console.log(this.ruleForm);
        this.trainTypeId = this.ruleForm.trainFormId;
        if (this.trainTypeId == "5") {
          this.showTrainTip = true;
        }
        this.Trainingtype = this.ruleForm.Trainingtype;
        this.shows = false;
        this.name1 = this.ruleForm.trainTypeNamePath;
        if (this.ruleForm.postName == undefined) {
          this.name2 = "";
          this.postName = "";
        } else {
          this.name2 = "/" + this.ruleForm.postName;
          this.postName = this.ruleForm.postName;
        }
        if (this.ruleForm.industryNamePath == undefined) {
          this.name3 = "";
          this.industryNamePath = "";
        } else {
          this.name3 = "/" + this.ruleForm.industryNamePath;
          this.industryNamePath = this.ruleForm.industryNamePath;
        }
        if (this.ruleForm.occupationNamePath == undefined) {
          this.name4 = "";
          this.occupationNamePath = "";
        } else {
          this.name4 = "/" + this.ruleForm.occupationNamePath;
          this.occupationNamePath = this.ruleForm.occupationNamePath;
        }
        if (this.ruleForm.trainLevelName == undefined) {
          this.name5 = "";
          this.trainLevelName = "";
        } else {
          this.name5 = "/" + this.ruleForm.trainLevelName;
          this.trainLevelName = this.ruleForm.trainLevelName;
        }
        this.trainForm.id = this.ruleForm.trainFormId;
        this.trainForm.name = this.ruleForm.trainTypeNamePath;
        this.getTree();
      }
    },
    showPop() {
      this.readonly = true;
      this.dialogVisible = true;
      this.trainForm = {};
      this.postForm = {};
      this.industryForm = {};
      this.occForm = {};
      this.levelForm = {};
      this.getTrainTypeList();
    },
    treeClose() {
      this.dialogVisibleSureTree = false;
    },
    dialogSure() {
      this.treeBack({
        trainForm: this.trainForm || {},
        postForm: this.postForm || {},
        industryForm: this.industryForm || {},
        occForm: this.occForm || {},
        levelForm: this.levelForm || {},
      });
      this.dialogVisibleSureTree = false;
      this.stus = "";
      this.stus2 = "";
      this.stus3 = "";
      this.stus4 = "";
      this.doClose();
    },
    // 给父组件传参
    parentPsBack() {
      if(this.threePostsLimit=="1"){
        // 判断培训类型是否是:三岗人员 || 全员培新
        if(this.trainForm.id=="10"){
          // 如果是三岗人员必须选择 岗位类型 行业类型 培训等级
          if(!this.postForm.id || !this.industryForm.id || !this.levelForm.id){
            this.$message.error("请选择岗位类型、行业类型、培训等级");
            return;
          }
        }
        // 判断培训类型是否是:三岗人员 || 全员培新
        if(this.trainForm.id=="11"){
          // 如果是三岗人员必须选择 岗位类型 行业类型 培训等级
          if(!this.postForm.id || !this.industryForm.id){
            this.$message.error("请选择岗位类型、行业类型");
            return;
          }
        }
      }
      if (this.type && this.data.children) {
        this.$message.warning("请选择培训类型的子节点");
        return;
      }
      if (this.typeStu == false) {
        this.dialogVisibleSureTree = true;

        if (this.postName != this.name21) {
          this.stus =
            "岗位类型：由【" +
            this.postName +
            "】  修改为【" +
            this.name21 +
            "】";
        }
        if (this.industryNamePath != this.name31) {
          this.stus2 =
            "行业类型：由【" +
            this.industryNamePath +
            "】  修改为【" +
            this.name31 +
            "】";
        }
        if (this.occupationNamePath != this.name41) {
          this.stus3 =
            "职业工种：由【" +
            this.occupationNamePath +
            "】  修改为【" +
            this.name41 +
            "】";
        }
        if (this.trainLevelName != this.name51) {
          if (this.TrainLevelTypeList.length == 0) {
            this.name51 = "";
          }
          this.stus4 =
            "培训等级：由【" +
            this.trainLevelName +
            "】  修改为【" +
            this.name51 +
            "】";
        }
        // let stusName = stus + stus2 + stus3 + stus4;

        // this.$confirm('您确定要修改：'+ stusName, '提示', {
        //   confirmButtonText: '确定',
        //   confirmButtonClass: 'bgc-bv',
        //   cancelButtonText: '取消',
        // }).then(() => {
        //   console.log(this.levelForm);
        //   this.treeBack({
        //     trainForm: this.trainForm || {},
        //     postForm: this.postForm || {},
        //     industryForm: this.industryForm || {},
        //     occForm: this.occForm || {},
        //     levelForm: this.levelForm || {},
        //   });
        //   this.doClose();
        // }).catch(() => {
        //   this.doClose();
        // });
      } else {
        this.treeBack({
          trainForm: this.trainForm || {},
          postForm: this.postForm || {},
          industryForm: this.industryForm || {},
          occForm: this.occForm || {},
          levelForm: this.levelForm || {},
        });
        this.doClose();
      }
    },
    treeBack(params) {
      this.ruleForm.Trainingtype = "";
      // let name1 = "";
      // let name2 = "";
      // let name3 = "";
      // let name4 = "";
      // let name5 = "";
      // if (params.trainForm.name) {
      //   name1 = params.trainForm.name;
      // }
      // if (params.postForm.name) {
      //   name2 = "/" + params.postForm.name;
      // }
      // if (params.industryForm.name) {
      //   name3 = "/" + params.industryForm.name;
      // }
      // if (params.occForm.name) {
      //   name4 = "/" + params.occForm.name;
      // }
      // if (params.levelForm.name) {
      //   name5 = "/" + params.levelForm.name;
      // }
      let name = this.name1 + this.name2 + this.name3 + this.name4 + this.name5;
      name = name.replace(/,/g, "-");
      this.ruleForm.Trainingtype = name;
      this.$emit("eventBtn", {
        trainFormId: this.trainForm.id || "",
        postFormId: this.postForm.id || "",
        industryFormId: this.industryForm.id || "",
        occFormId: this.occForm.id || "",
        levelFormId: this.levelForm.id || "",
        trainFormIdPath: this.trainForm.idPath || "",
      });
    },
    // 关闭回调
    doClose() {
      this.dialogVisible = false;
      this.trainTypeList = [];
      this.PostTypeList = []; // 岗位
      this.showPost = false;
      this.IndustryTypeList = []; //行业
      this.showIndustry = false;
      this.OccupationTypeList = []; // 职业
      this.showOccupation = false;
      this.TrainLevelTypeList = []; //培训等级
      this.showTrainLevel = false;
      this.trainTypeId = "";
      this.postId = "";
      this.industryId = "";
      this.name1 = "";
      this.name2 = "";
      this.name3 = "";
      this.name4 = "";
      this.name5 = "";
      this.readonly = false;
      this.showTrainTip = false;
      this.OccupationText = "";
      this.postText = "";
      this.IndustryText = "";
      this.LevelText = "";
    },
    doCancel() {
      this.dialogVisible = false;
      this.doClose();
      if(this.parentCanecl){
        this.parentCanecl();
      }
    },
    // 清空
    clear() {
      this.ruleForm.Trainingtype = "";
    },
    // 培训类型点击回调
    changeTrain(data) {
      if (this.type && data.id == "5") {
        this.showTrainTip = true;
      } else {
        this.showTrainTip = false;
      }
      if (this.type && data.id == '2') {
         this.showGsyfTip = true;
      } else {
        this.showGsyfTip = false;
      }
      this.data = data;
      this.postForm.id = "";
      this.postForm.name = "";
      this.industryForm.id = "";
      this.industryForm.name = "";
      this.occForm.id = "";
      this.occForm.name = "";
      this.levelForm.id = "";
      this.levelForm.name = "";
      // console.log(data);
      this.trainForm.id = data.id;
      this.trainForm.name = data.namePath;
      this.trainForm.idPath = data.idPath.split(",")[0];

      //
      if (data.namePath) {
        this.name1 = data.namePath.replace(/,/g, "-");
      }
      this.name2 = "";
      this.name3 = "";
      this.name4 = "";
      this.name5 = "";
      //
      this.trainTypeId = data.id;
      this.postId = "";
      this.industryId = "";
      this.showPost = data.showPost;
      this.showIndustry = data.showIndustry;
      this.showOccupation = data.showOccupation;
      this.showTrainLevel = data.showTrainLevel;
      data.showPost ? this.getPostList() : "";
      data.showTrainLevel ? this.getLevelList() : "";
      data.showOccupation ? this.getOccupationList() : "";
      data.showIndustry ? this.getIndustryList() : "";
      this.currentState.showPost = this.showPost;
      this.currentState.showIndustry = this.showIndustry;
      this.currentState.showOccupation = this.showOccupation;
      this.currentState.showTrainLevel = this.showTrainLevel;
    },
    // 岗位类型点击回调
    changePost(data) {
      this.industryForm.id = "";
      this.industryForm.name = "";
      this.occForm.id = "";
      this.occForm.name = "";
      this.levelForm.id = "";
      this.levelForm.name = "";
      //
      this.postForm.id = data.postId;
      this.postForm.name = data.postName;
      //
      if (data.postName) {
        this.name2 = "/" + data.postName;
        this.name21 = data.postName;
      }
      // this.name3 = "";
      // this.name4 = "";
      // this.name5 = "";
      //
      this.showIndustry = !data.hiddenIndustry;
      this.showTrainLevel = !data.hiddenTrainLevel;

      if (data.relevanceIndustry) {
        this.showIndustry = true;
        this.postId = data.postId;
        this.getIndustryList();
      } else {
        this.showIndustry = this.currentState.showIndustry;
        this.postId = "";
        this.getIndustryList();
      }
      if (data.relevanceOccupation) {
        this.showOccupation = true;
        this.getOccupationList();
        this.postId = data.postId;
      } else {
        this.showOccupation = this.currentState.showOccupation;
        this.postId = "";
        this.industryId = "";
        this.getOccupationList();
      }
      //
      if (data.relevanceTrainLevel) {
        this.showTrainLevel = true;
        this.postId = data.postId;
        this.getLevelList();
      } else {
        this.showTrainLevel = this.currentState.showTrainLevel;
        this.postId = "";
        this.getLevelList();
      }
    },
    // 行业类型点击回调
    changeIndustry(data) {
      this.occForm.id = "";
      this.occForm.name = "";
      this.levelForm.id = "";
      this.levelForm.name = "";
      //
      this.industryForm.id = data.id;
      this.industryForm.name = data.namePath;
      //
      if (data.namePath) {
        this.name3 = "/" + data.namePath.replace(/,/g, "-");
        this.name31 = data.namePath.replace(/,/g, "-");
      }
      // this.name4 = "";
      // this.name5 = "";
      //
      if (data.relevanceOccupation) {
        this.showOccupation = true;
        this.postId = "";
        this.industryId = data.id;
        this.getOccupationList();
      } else {
        this.showOccupation = this.currentState.showOccupation;
        this.industryId = "";
        this.getOccupationList();
      }
      if(this.trainForm.id=="10"){
        this.getLevelList();
      }
    },
    // 职业类型点击回调
    changeOccupation(data) {
    //   this.levelForm.id = "";
    //   this.levelForm.name = "";
      // this.name5 = "";
      //
      //暂无联动需求
      this.occForm.id = data.id;
      this.occForm.name = data.namePath;
      if (data.namePath) {
        this.name4 = "/" + data.namePath.replace(/,/g, "-");
        this.name41 = data.namePath.replace(/,/g, "-");
      }
    },
    // 培训等级点击回调
    changeLevel(data) {
      //暂无联动需求
      this.levelForm.id = data.trainLevelId;
      this.levelForm.name = data.trainLevelName;
      if (data.trainLevelName) {
        this.name5 = "/" + data.trainLevelName;
        this.name51 = data.trainLevelName;
      }
    },
    /**
     * tree接口
     */
    getTrainTypeList() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          this.trainTypeList = res.data;
        })
        .catch(() => {
          return;
        });
    },
    // 岗位
    getPostList() {
      this.PostTypeList = [];
      this.$post("/sys/category/post/list", { trainTypeId: this.trainTypeId })
        .then((res) => {
          if (res.status == 0) {
            (res.data || []).map((item) => {
              item.label = item.postName;
              this.PostTypeList.push(item);
            });
          }
        })
        .catch(() => {
          return;
        });
    },
    // 行业
    getIndustryList() {
      let params = {
        trainTypeId: this.trainTypeId,
      };
      if (this.postId) {
        params.postId = this.postId;
      }
      this.$post("/sys/category/industry/tree", params)
        .then((res) => {
          if (res.status == 0) {
            this.IndustryTypeList = res.data;
          }
        })
        .catch(() => {
          return;
        });
    },
    // 职业
    getOccupationList() {
      let params = {
        trainTypeId: this.trainTypeId,
      };
      if (this.postId) {
        params.postId = this.postId;
      }
      if (this.industryId) {
        params.industryId = this.industryId;
      }
      this.OccupationTypeList = [];
      this.$post("/sys/category/occupation/tree", params)
        .then((res) => {
          if (res.status == 0) {
            this.OccupationTypeList = res.data;
          }
        })
        .catch(() => {
          return;
        });
    },
    // 等级
    getLevelList() {
      let params = {
        trainTypeId: this.trainTypeId,
      };
      if (this.postId) {
        params.postId = this.postId;
      }
      this.$post("/sys/category/train-level/list", params)
        .then((res) => {
          if (res.status == 0) {
            this.TrainLevelTypeList = [];
            (res.data || []).map((item) => {
              item.label = item.trainLevelName;
              this.TrainLevelTypeList.push(item);
            });
          }
        })
        .catch(() => {
          return;
        });
    },
    // 筛选
    filterPost(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    filterIndustry(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    filterOccupation(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    filterLevel(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getTree() {
      this.$post("/sys/category/train-type/info", {
        trainTypeId: this.trainTypeId,
      })
        .then((ret) => {
          console.log(ret);
          let data = ret.data;
          // if(ret.data.showOccupation == )
          this.showPost = data.showPost;
          this.showIndustry = data.showIndustry;
          this.showOccupation = data.showOccupation;
          this.showTrainLevel = data.showTrainLevel;
          data.showPost ? this.getPostList() : "";
          data.showTrainLevel ? this.getLevelList() : "";
          data.showOccupation ? this.getOccupationList() : "";
          data.showIndustry ? this.getIndustryList() : "";
          this.currentState.showPost = this.showPost;
          this.currentState.showIndustry = this.showIndustry;
          this.currentState.showOccupation = this.showOccupation;
          this.currentState.showTrainLevel = this.showTrainLevel;
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  min-width: 200px;
  border: 1px solid #d8d8d8;
  height: 300px;
  .item-ovy-a {
    overflow-y: auto;
  }
  h3 {
    padding: 10px;
    border-bottom: 1px dashed #717171;
    text-align: center;
  }
  > div {
    padding: 10px 15px;
  }
}
.borderL0 {
  border-left: 0;
}
</style>
<style lang="less" scoped>
.treebox {
  // .el-dialog__header {
  //   background-color: #1b2e4b;
  //   text-align: center;
  //   .el-dialog__title {
  //     color: #fff;
  //   }
  //   .el-dialog__headerbtn {
  //     top: 20px;
  //   }
  //   .el-dialog__headerbtn .el-dialog__close {
  //     color: #fff;
  //     font-size: 1.2rem;
  //   }
  //   .el-icon-close:before {
  //     border: 1px solid #fff;
  //     border-radius: 50%;
  //   }
  // }
  /deep/.el-dialog__body {
    padding:30px 20px 0 30px;
  }
 /deep/ .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #5c6be8;
    color: #fff;
  }
  /deep/.el-tree-node__content {
    padding-right: 0.625rem;
  }
  .tree {
    .el-input__inner {
      border-color: #dcdfe6 !important;
      width: 100% !important;
    }
  }
}
</style>
